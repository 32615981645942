// import external dependencies
import 'jquery';

// Import everything from autoload


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';


// import then needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// import the Facebook and Twitter icons
import { faFacebook,faInstagram,faTwitter,faYoutube,faLinkedin} from '@fortawesome/free-brands-svg-icons';
import { faChevronLeft,faChevronRight,faPhone} from '@fortawesome/pro-regular-svg-icons';

// add the imported icons to the library
library.add(faChevronLeft,faChevronRight,faPhone,faFacebook,faInstagram,faTwitter,faYoutube,faLinkedin);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());


$(document).ready(function() {

  // Check for click events on the navbar burger icon
  $('.navbar-burger').click(function() {

      // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
      $('.navbar-burger').toggleClass('is-active');
      $('.navbar-menu').toggleClass('is-active');

  });
});

var elementPosition = $('#cta-contact').offset();

$(window).scroll(function(){
        if($(window).scrollTop() > elementPosition.top){
              $('#cta-contact').removeClass('home-cta-mobile');
              $('#cta-contact').css('position','fixed').css('bottom','20px').css('top','unset').css('height','68px');
        } else {
            $('#cta-contact').removeAttr('style');
            $('#cta-contact').addClass('home-cta-mobile');
        }    
});

$('figure div').removeClass('wp-block-embed__wrapper');
$('iframe').addClass('has-ratio');

var switching = false

$('.js-card').on('click', function(){

    if (switching) {
       return false
    }





    switching = true;
    
    $(this).toggleClass('is-switched');

    if ($(this).hasClass('is-switched')) {
      $(this).find('.card__wrapper').css('margin-bottom', '2rem').css('height', $(this).find('.card__side--back').height() +'px');
    } else {
      $(this).find('.card__wrapper').removeAttr('style');
    }

    window.setTimeout(function () { 
      $(this).children().children().toggleClass('is-active');
       switching = false;
    }, 500 / 2)

});